<template>
  <div class="index_wrap">
    <h1 class="h_none">蓝灯鱼AI开放平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div style="height: 74px"></div>
    <div style="margin-bottom: 41px;margin-top: 94px" class="dowmload_center">
      <section class="top">下载中心</section>
      <section class="document">
        <p class="document_title document_title_1">文档</p>
        <div class="document_cen">
          <ul v-for="(item,index) in document" :key="index" class="document_item">
            <li>
              <img style="width: 65px;height: 68px" src="../../assets/images/downloadCenter/DOCX.png" alt="">
            </li>
            <li>
              <p class="title">{{item.resourceName}}</p>
              <p class="name" style="margin-top: 9px;margin-bottom: 8px;display: block;display: block;height: 4px"></p>
              <p class="detail">
                <span>{{item.resourceSize}}</span>
                <span>{{item.resourceVersion}}</span>
                <span>{{item.resourceUpdateTime}}</span>
              </p>
            </li>
            <li>
              <dl>
                <dt @click="loadDown(item)"><img style="width: 36px;height: 32px;display: block;cursor: pointer"
                                                     src="../../assets/images/downloadCenter/load.png" alt=""></dt>
                <dd style="margin-top: 8px">下载</dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <div style="margin-bottom: 61px" class="dowmload_center">
      <section class="document">
        <p class="document_title document_title_2">效率工具</p>
        <div class="document_cen">
          <ul v-for="(item,index) in tools" :key="index" class="document_item">
            <li>
              <img style="width: 65px;height: 68px" src="../../assets/images/downloadCenter/zip.png" alt="">
            </li>
            <li>
              <p class="tool_title">{{item.resourceName}}</p>
              <p style="margin-top: 18px" class="detail">
                <span>{{item.resourceSize}}</span>
                <span>{{item.resourceVersion}}</span>
                <span>{{item.resourceUpdateTime}}</span>
              </p>
            </li>
            <li>
              <dl>
                <dt @click="loadDown(item)"><img style="width: 36px;height: 32px;display: block;cursor: pointer"
                                                     src="../../assets/images/downloadCenter/load.png" alt=""></dt>
                <dd style="margin-top: 8px">下载</dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <footers></footers>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login :laod="islaod" @loginDialog="loginDialog" :dialog-close="dialogClose"
             @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import login from "@/components/common/login"
  import { mapState, mapMutations, mapGetters } from "vuex";
  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,商标查询，商标近似查询，商标图形查询，专利翻译，专利查询，专利检索'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是将人工智能技术运用到知识产权行业的平台。通过AI技术智能化商标查询,专利翻译,专利检索；提供在线使用和API接入等服务'
      }],
    },
    data() {
      return {
        isCN: true,
        islaod: false,
        //是否直接下载安装包
        directFlag: true,
        dialogClose: false,
        dialogVisible: false,
        //'/file/CPC图片压缩工具2.2.4.exe'
        document: [],
        tools: []
      }
    },

    mounted() {
      this.getList()
    },
    components: {
      footers,
      login
    },
    watch: {
      directFlag(val) {
        if (this.tool.getCookie("auth")) {
          this.loadFilesWin()
        }
      }
    },
    computed: {
      ...mapState(["me", "noticeList", "headerBgBool"]),
    },
    methods: {
      //获取资源
      getList(){
        this.$axios.get('/lantern/lanresource/list').then(({data}) => {
          //document  tools
          if(data.code == 0){
            this.document = data.data.filter(item => item.resourceDirName == '文档')
            this.tools = data.data.filter(item => item.resourceDirName == '效率工具')

            if(this.$route.query.id){
              let downloadItem = null

              for (let i = 0; i < data.data.length; i++){
                let item = data.data[i]
                switch (Number(this.$route.query.id)){
                  case 1:
                    if (item.resourceName == "机翻Trados插件"){
                      downloadItem = item
                    }
                    break
                  case 2:
                    if (item.resourceName == "机翻word插件"){
                      downloadItem = item
                    }
                    break
                  case 3:
                    if (item.resourceName == "机翻Excel插件"){
                      downloadItem = item
                    }
                    break
                  case 4:
                    if (item.resourceName == "专利五书拆分"){
                      downloadItem = item
                    }
                    break
                  case 5:
                    if (item.resourceName == "专利CPC附图一键压缩"){
                      downloadItem = item
                    }
                    break
                  case 6:
                    if (item.resourceName == "CPC官文提取"){
                      downloadItem = item
                    }
                    break
                  case 7:
                    if (item.resourceName == "专利撰写形式核查助手"){
                      downloadItem = item
                    }
                    break
                }
              }

              if (downloadItem != null){
                this.Installation(downloadItem)
              }
            }
          }
        })
      },
      //判断是否是IE
      isIE() {
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          return true
        } else {
          return false
        }
      },
      loadDown(item) {
        localStorage.setItem('download',JSON.stringify(item))
        if (this.tool.getCookie("auth")) {
          this.loadFilesWin()
        } else {
          this.Loadflag = true
          this.directFlag = true
          this.dialogVisible = true
        }
      },
      //安装包下载工具
      Installation(item){
        ///lanresource/uploadLog
        this.$axios.post('/lantern/lanresource/uploadLog',{
          "userId": this.me.userId,
          "userName": this.me.username,
          "phone": this.me.phone,
          "resourceName": item.resourceName
        }).then(({data}) => {
          //document  tools
          if(data.code == 0){
            let h = item.resourceUrl.substring(item.resourceUrl.lastIndexOf('.') + 1)

            let alink = document.createElement("a");

            // alink.download="teamplte"//文件名,大部分浏览器兼容,IE10及以下不兼容

            alink.href=window.location.origin + item.resourceUrl;//创建 url地址
            alink.download = `${item.resourceName}(${item.resourceVersion}).${h}`;
            alink.click(); //自动点击
          }
        })

      },
      //一次性下载多个文件
      loadFilesWin() {
        let item = JSON.parse(localStorage.getItem('download'))
        this.Installation(item)
        // if(item.type == 'word'){
        //   var urlsArray = item.url.split(",");
        //   let _this = this;
        //   for (let i = 0; i < urlsArray.length; i++) {
        //     if(urlsArray[i].indexOf('.pdf')){
        //       window.open(urlsArray[i])
        //       continue;
        //     }
        //     const iframe = document.createElement("iframe");
        //     iframe.style.display = "none"; // 防止影响页面
        //     iframe.style.height = 0; // 防止影响页面
        //     iframe.src = urlsArray[i];
        //     document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
        //     // 5分钟之后删除
        //     setTimeout(() => {
        //       iframe.remove();
        //     }, 5 * 60 * 1000);
        //   }
        // }else {
        //
        // }
      },
      //走马灯
      select(item) {
        this.name = this.lun[item].name
      },
      // 登录弹层隐藏
      loginDialog(data) {
        if (this.Loadflag == true) {
          this.loadFilesWin()
        }
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
      //登录
      login() {
        if (this.tool.getCookie("auth")) {
          this.$message({
            message: '您已经登录成功',
            type: 'success'
          });
        } else {
          this.Loadflag = false
          this.dialogVisible = true
        }
      },
      //打开个人中心
      open() {
        if (this.tool.getCookie("auth")) {
          this.$router.push({path: '/personalCenter/account'})
        } else {
          this.dialogVisible = true
        }
      },
    }
  }
</script>
<style lang="less" scoped>
  .index_wrap {
    background: #F1F3FA;

    .dowmload_center {
      min-height: 500px;

      .top {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        position: relative;
        width: 158px;
        margin: 0 auto;
        margin-bottom: 51px;
        z-index: 0;

        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 158px;
          height: 12px;
          background: rgba(38, 77, 216, 0.58);
          border-radius: 5px;
        }
      }

      .document {
        width: 1199px;
        margin: 0 auto;

        .document_title {
          font-size: 22px;
          font-weight: 500;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          padding-left: 33px;
          box-sizing: border-box;

          &.document_title_1 {
            width: 146px;
            height: 48px;
            background: url("../../assets/images/patent/down_title1.png") no-repeat 100% 100%;
          }

          &.document_title_2 {
            width: 212px;
            height: 48px;
            background: url("../../assets/images/patent/down_title2.png") no-repeat 100% 100%;
          }
        }

        .document_cen {
          width: 1199px;
          height: auto;
          background: #E2E8F6;
          padding: 31px 15px 37px 14px;
          box-sizing: border-box;
          border-radius: 0px 10px 10px 10px;

          .document_item {
            display: flex;
            align-items: center;
            /*justify-content: space-between;*/
            padding: 31px 41px 21px 30px;
            box-sizing: border-box;
            width: 1170px;
            height: 138px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
            border-radius: 10px;
            position: relative;
            margin-bottom: 24px;

            .title {
              font-size: 20px;
              font-weight: 500;
              color: #333333;
            }

            .tool_title {
              font-size: 22px;
              font-weight: 500;
              color: #2E50D8;
            }

            .name {
              font-size: 18px;
              font-weight: 500;
              color: #666666;
            }

            .detail {
              font-size: 16px;
              font-weight: 400;
              color: #666666;

              span:nth-of-type(1) {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
              }

              span:nth-of-type(2) {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                margin: 0 42px;
              }

              span:nth-of-type(3) {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
              }
            }

            & li:nth-of-type(1) {
              margin-right: 23px;
            }

            & li:nth-of-type(3) {
              position: absolute;
              right: 41px;
              top: 53px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>

